[type=radio].with-gap:checked+label:after, [type=radio]:checked+label:after {
  background-color: #6599FE;
}
[type=radio].with-gap:checked+label:after, [type=radio].with-gap:checked+label:before,
[type=radio]:checked+label:after {
  border: 2px solid #6599FE;
}
[type=radio]:not(:checked)+label:after, [type=radio]:not(:checked)+label:before {
  border: 2px solid #8A96AC
}
[type=radio].with-gap:checked+label:after, [type=radio].with-gap:checked+label:before,
[type=radio]:checked+label:after, [type=radio]:checked+label:before,
[type=radio]:not(:checked)+label:after, [type=radio]:not(:checked)+label:before {
  border-radius: 4px;
}
// Jira support widget
#jsd-widget {
  bottom: 3.44rem !important;
}
