:root {
  --bg-color-green: #ECF7ED;
  --bg-color-orange: #fff3e0;
  --bg-color-blue: #ecf2ff;

  --text-color-label: #8A96AC;

  --bg-color-tag-share: hsl(38, 100%, 75%);
  --bg-color-tag-share-dark: hsl(38, 100%, 60%);
}
