$sizes: 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64;

@each $size in $sizes {
  .gap-#{$size} {
    gap: #{$size * 0.25rem};
  }
  .gap-x-#{$size} {
    column-gap: #{$size * 0.25rem};
  }
  .gap-y-#{$size} {
    row-gap: #{$size * 0.25rem};
  }
}
