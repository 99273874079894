
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        























































































































































































































@import '@/assets/scss/global/variables.scss';
@import "@/assets/scss/global/normalize.scss";
@import "@/assets/scss/global/fonts.scss";
@import "@/assets/scss/global/custom.scss";
@import "@/assets/scss/global/gap.scss";
 .app {
   background: #f5f6fa;
   min-height: 100vh;
 }
