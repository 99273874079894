a,
input,
button,
select,
textarea {
  &:active,
  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

