
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        








































































.vue-notification {
  &.apiError {
    pointer-events: none;
    position: relative;
    background: #dc5555;
    border-left: 5px solid #a83131;

    &::after {
      position: absolute;
      top: .5rem;
      right: 1rem;
      content: '\00D7';
      font-size: 2rem;
      line-height: 1rem;
      cursor: pointer;
      pointer-events: auto;
      font-weight: 100;
    }

    .notification-title {
      font-weight: 500;
      line-height: 1.3rem;
      margin-right: 2rem;
    }

    .notification-copy-btn {
      border: 1px solid #fff;
      font-size: .8rem;
      color: #e54d42;
      background: #fff;
      padding: 0.1rem 0.3rem;
      cursor: copy;
      pointer-events: auto;
      margin-top: .3rem;
    }
  }
}
